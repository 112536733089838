import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { graphql } from "gatsby";
import TextPostPreview from '../components/TextPostPreview';
import Seo from '../components/Seo';

const Grid = styled.main` 
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
`
const Text = ({data}) => {
  const posts = data?.allMarkdownRemark.edges

  return (
    <>
      <Seo title="Nigel Lendon - Texts" description="A collection of Nigel's writings and publications."/>
      <Layout>
        <Grid>
          {posts.map(({ node }, i) => (
            <TextPostPreview key={i}  {...node} />
          ))}
        </Grid>
      </Layout>
    </>
  )
}

export const query = graphql`
  query TextPosts {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "text-post"}}}, sort: {fields: frontmatter___year, order: ASC}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            article {
              publicURL
            }
            year
            externalLink
            featuredimage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;


export default Text